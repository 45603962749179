import React, { createContext, useEffect, useState } from "react";
import Callaxios from "./Callaxios";
export const Simplecontext = createContext();

export default function Simplecontextprovider({ children }) {
  const [framedata, setframedata] = useState([]);
  const [orderproductdata, setorderproductdata] = useState([]);
  useEffect(() => {
    Getframe();
    Getorderproduct();
  }, []);
  const [urlPath, seturlPath] = useState(
    window.location.pathname ? window.location.pathname : "/"
  );

  const Getframe = async () => {
    try {
      let data = await Callaxios("get", "frame/frame/");
      if (data.status === 200) {
        setframedata(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const Getorderproduct = async () => {
    try {
      let data = await Callaxios("get", "order/orderproduct");
      if (data.status === 200) {
        setorderproductdata(data.data);
      }
    } catch (error) {}
  };

  return (
    <Simplecontext.Provider
      value={{
        framedata,
        Getframe,
        Getorderproduct,
        orderproductdata,
        urlPath,
        seturlPath,
      }}
    >
      {children}
    </Simplecontext.Provider>
  );
}
