import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiWoodFrame } from "react-icons/gi";
import { MdOutlineLogout, MdPlace } from "react-icons/md";
import { AiFillDashboard } from "react-icons/ai";
import { HiColorSwatch } from "react-icons/hi";
import { ImBlogger, ImPriceTags } from "react-icons/im";
import { RiFileCopy2Fill, RiProductHuntFill } from "react-icons/ri";
import Scripts from "./Scripts";
import { Simplecontext } from "./Simplecontext";
export default function Header() {
  let navigate = useNavigate();
  const { urlPath, seturlPath } = useContext(Simplecontext);

  useEffect(() => {
    Scripts();
  }, []);
  const logoutfn = () => {
    window.localStorage.removeItem("fotoframe_token");
    return navigate("/login");
  };
  return (
    <div>
      <nav className="sidebar">
        <div className="sidebar-header ">
          <a href="/" className="sidebar-brand">
            <img
              src="..\assets\images\logo\fotoframe-logo2.png"
              alt="light theme"
              width={65}
              height={65}
              style={{ marginLeft: "-9px" }}
            />
          </a>
          <div className="sidebar-toggler not-active">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="sidebar-body">
          <ul className="nav">
            <li className="nav-item nav-category">Main</li>
            <li
              className={`nav-item ${urlPath === "/" ? "active" : ""}`}
              style={{ padding: "4px 0" }}
            >
              <Link to="/" className="nav-link" onClick={() => seturlPath("/")}>
                <AiFillDashboard size={23} />
                <span className="link-title">
                  <b>Dashboard</b>
                </span>
              </Link>
            </li>

            <li
              className={`nav-item ${urlPath === "/orders" ? "active" : ""}`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/orders"
                className="nav-link"
                onClick={() => seturlPath("/orders")}
              >
                <RiFileCopy2Fill size={25} />
                <span className="link-title ">
                  <b>Orders</b>
                </span>
              </Link>
            </li>
            <li
              className={`nav-item ${urlPath === "/product" ? "active" : ""}`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/product"
                className="nav-link"
                onClick={() => seturlPath("/product")}
              >
                <RiProductHuntFill size={24} />
                <span className="link-title ">
                  <b>Products</b>
                </span>
              </Link>
            </li>
            <li
              className={`nav-item ${
                urlPath === "/producttheme" ? "active" : ""
              }`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/producttheme"
                className="nav-link"
                onClick={() => seturlPath("/producttheme")}
              >
                <HiColorSwatch size={23} />
                <span className="link-title ">
                  <b>Product theme</b>
                </span>
              </Link>
            </li>

            <li
              className={`nav-item ${urlPath === "/frame" ? "active" : ""}`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/frame"
                className="nav-link"
                onClick={() => seturlPath("/frame")}
              >
                <GiWoodFrame size={22} />
                <span className="link-title ">
                  <b>Frame</b>
                </span>
              </Link>
            </li>
            <li
              className={`nav-item ${
                urlPath === "/frameprice" ? "active" : ""
              }`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/frameprice"
                className="nav-link"
                onClick={() => seturlPath("/frameprice")}
              >
                <ImPriceTags size={23} />
                <span className="link-title ">
                  <b>Frame Price</b>
                </span>
              </Link>
            </li>

            <li
              className={`nav-item ${urlPath === "/city" ? "active" : ""}`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/city"
                className="nav-link"
                onClick={() => seturlPath("/city")}
              >
                <MdPlace size={25} style={{ marginLeft: "-3px" }} />
                <span className="link-title">
                  <b>City</b>
                </span>
              </Link>
            </li>

            <li
              className={`nav-item ${urlPath === "/blog" ? "active" : ""}`}
              style={{ paddingBottom: "4px" }}
            >
              <Link
                to="/blog"
                className="nav-link"
                onClick={() => seturlPath("/blog")}
              >
                <ImBlogger size={19} style={{ marginRight: "3px" }} />
                <span className="link-title ">
                  <b>Blogs</b>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="page-wrapper">
        <nav className="navbar">
          <a href="/vessel" className="sidebar-toggler">
            <i data-feather="menu" />
          </a>
          <div className="navbar-content">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => logoutfn()}
                  className="nav-link ms-0"
                >
                  <MdOutlineLogout className="me-1 icon-md" />
                  <span style={{}}>Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
