import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSearch } from "react-icons/bi";
import DataTable from "react-data-table-component";
import { Simplecontext } from "./Simplecontext";
import Callaxios from "./Callaxios";
import moment from "moment";
import { Link } from "react-router-dom";
import { Capitalize } from "./Capitalize";

export default function Orders() {
  const { orderproductdata } = useContext(Simplecontext);
  const [orderdata, setorderdata] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");

  useEffect(() => {
    Getorders();
  }, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-right",
      theme: "dark",
    });
  const Getorders = async () => {
    try {
      let data = await Callaxios("get", "order/orders/");
      if (data.status === 200) {
        setorderdata(data.data);
      }
    } catch (error) {
      notifyerror("Something went wrong ");
    }
  };
  const deletetask = async (itmid) => {
    notify("delete");
  };
  const orderfunction = (itm) => {
    if (orderproductdata) {
      let order_qs = orderproductdata.filter((t) => t.order.id === itm.id);
      return order_qs[0];
    } else {
      return null;
    }
  };

  const rowNumber = (row) =>
    orderdata
      .filter((t) =>
        t.Customer_name.toLowerCase().includes(searchvalue.toLowerCase())
      )
      .indexOf(row) + 1;
  const columns = [
    {
      name: <div>#</div>,
      selector: (row) => rowNumber(row),
      width: "50px",
    },
    {
      name: "Order.No",
      selector: (itm) => (
        <div>
          <Link to={{ pathname: "/orderproduct" }} state={{ someArray: itm }}>
            F{itm.created_date.split("T")[1].split(".")[1]}f{itm.id}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: (itm) => <div>{itm.Customer_name}</div>,
    },
    {
      name: "Status",
      selector: (itm) => (
        <div className="p-2 d-flex-col text-center">
          <button
            disabled
            className={`h-auto w-auto rounded p-1 btn ${
              itm?.status === "new"
                ? "btn-secondary"
                : itm?.status === "dispatch"
                ? "btn-warning"
                : itm?.status === "delivered"
                ? "btn-success"
                : "btn-danger"
            }`}
          >
            {itm?.status ? Capitalize(itm?.status) : ""}
          </button>
          <br />
          <select
            onChange={(e) => changestatus(itm.id, e.target.value)}
            className="form-select mt-1"
            style={{ padding: ".469rem 2.4rem .469rem .7rem" }}
          >
            <option value="" hidden>
              Change Status
            </option>
            <option value="new">New</option>
            <option value="dispatch">Dispatch</option>
            <option value="delivered">Delivered</option>
            <option value="delete">Delete</option>
          </select>
        </div>
      ),
      width: "190px",
    },
    {
      name: "Price",
      selector: (itm) => (
        <div className="d-flex-col text-center">
          {itm.total_price} <span className="aed"> AED</span>
        </div>
      ),
    },
    {
      name: "Paid",
      selector: (itm) => (
        <div className="d-flex-col text-center">
          <button
            disabled
            className={`h-auto w-auto rounded  p-1 btn  ${
              itm.paidstatus ? "btn-success" : "btn-danger"
            }`}
          >
            {itm.paidstatus ? "Paid" : "Not Paid"}
          </button>
          <br />
          <select
            onChange={(e) => changepaidstatus(itm.id, e.target.value)}
            className="form-select mt-1"
            style={{ padding: ".469rem 2.4rem .469rem .7rem" }}
          >
            <option value="" hidden>
              Change Status
            </option>
            <option value={true}>Paid</option>
            <option value={false}>Not Paid</option>
          </select>
        </div>
      ),
      width: "175px",
    },
    {
      name: "Date",
      selector: (itm) => (
        <div className="d-flex-col text-center">
          {moment(itm.created_date).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
      ),
      width: "230px",
    },
  ];

  const customStyles = {
    cells: {
      style: {
        border: "0.5px solid #f5f2f2 ",
      },
    },

    headCells: {
      style: {
        minHeight: "40px",
        border: "0.5px solid #e8e2e2 ",
        borderTopWidth: "1.5px",
      },
    },
    filter: {
      style: {
        border: "1px solid gray",
      },
    },
  };
  const changepaidstatus = async (order_id, status) => {
    try {
      let data = await Callaxios("patch", "order/orders/", {
        id: order_id,
        paidstatus: status,
      });
      if (data.data.Status === 200) {
        notify("status changed");
        Getorders();
      } else {
        notifyerror("Something went wrong");
      }
    } catch (error) {}
  };
  const changestatus = async (orderid, status) => {
    try {
      let data = await Callaxios("patch", "order/orders/", {
        id: orderid,
        status: status,
      });
      if (data.data.Status === 200) {
        notify("status changed");
        Getorders();
      } else {
        notifyerror("Something Went Wrong");
      }
    } catch (error) {}
  };
  return (
    <div className="page-wrapper p-3 mt-5">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-6">
                  <h6 className="card-title text-start text-bold">Orders</h6>
                </div>
                <div className="col-6">
                  <form className="search-form ml-auto">
                    <div className="input-group">
                      <div className="input-group-text">
                        <BiSearch />
                      </div>
                      <input
                        onChange={(e) =>
                          e.target.value.charAt(0) !== " "
                            ? setsearchvalue(e.target.value)
                            : ""
                        }
                        value={searchvalue}
                        type="text"
                        className="form-control"
                        id="navbarForm"
                        placeholder="Search here..."
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div className="table-responsive pt-3">
                <DataTable
                  pagination
                  // highlightOnHover
                  columns={columns}
                  data={orderdata.filter((t) =>
                    t.Customer_name.toLowerCase().includes(
                      searchvalue.toLowerCase()
                    )
                  )}
                  defaultSortField="_id"
                  defaultSortAsc={false}
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  // fixedHeader
                  // fixedHeaderScrollHeight='63vh'
                  // className="tablereact  tablereact "
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
