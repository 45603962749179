export function Capitalize(word) {
  if (!word) {
    return "";
  }
  const words = word.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}
